import React from 'react';
import Article from 'components/help/helpArticle';

const Content = () => {
  return (
    <div>
      <p>
        <strong>No, it won&apos;t.</strong>
      </p>
      <p>
        LiveSession script is designed to works asynchronously and should never cause website render
        or performance problems while it does its own work.{' '}
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'Will LiveSession tracking script slow my website?',
  metaDescription: 'Check this article to find out if LiveSession will slow down your website',
  canonical: '/help/will-livesession-slow-down-loading-of-my-website/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped
    related={related}
    section="Product"
    title="Will LiveSession slow down loading of my website?"
    {...frontmatter}
  />
);
